@import url("https://fonts.googleapis.com/css2?family=Assistant&family=Martel:wght@700;900&display=swap");

body {
  margin: 0;
}
 img {
  display:block;
  object-fit:cover;
  width:100%;
  height:100%;
 }